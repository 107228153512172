<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Promo List
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top:27px;"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add()"
										id="btn"
										color="success"
										style="margin-top:27px; margin-left:5px;"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height:900px;">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="add(props.rowData)"
										color="warning"
										size="sm"
										class="py-0"
									>
										<CIcon name="cil-pencil" />
									</CButton>
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left:3px;"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>

		<CModal
			:show.sync="modal"
			:no-close-on-backdrop="true"
			:centered="true"
			title="Modal title 2"
			size="lg"
			color="info"
		>
			<CRow>
				<CCol md="12">
					<CInput label="Name" v-model="frm.name" placeholder="Name" />
				</CCol>
			</CRow>
			<CRow>
				<CCol md="3">
					<CInput
						label="Discount"
						type="number"
						v-model.number="frm.discount"
						placeholder="Discount"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						label="BTE"
						v-model="frm.bte"
						placeholder="Barrier to entry"
					/>
				</CCol>
				<CCol md="3">
					<div role="group" class="form-group">
						<label for="active" class=""> Active </label>
						<select
							v-model.number="frm.active"
							id="active"
							class="form-control"
						>
							<option :data-key="true" :value="true">
								ACTIVE
							</option>
							<option :data-key="false" :value="false">
								NON ACTIVE
							</option>
						</select>
					</div>
				</CCol>
				<CCol md="6">
					<CInput
						label="Start Date"
						type="date"
						v-model="frm.start"
						placeholder="start date"
					/>
				</CCol>
				<CCol md="6">
					<CInput
						label="End Date"
						type="date"
						v-model="frm.end"
						placeholder="end date"
					/>
				</CCol>
				<CCol md="12">
					<CTextarea
						label="Condition"
						v-model="frm.condition"
						placeholder="condition..."
						rows="4"
					/>
				</CCol>
			</CRow>
			<template #header>
				<h6 class="modal-title">
					{{ selectedId != null ? "Edit " + frm.name : "Add new data" }}
				</h6>
				<CButtonClose @click="modal = false" class="text-white" />
			</template>
			<template #footer>
				<CButton @click="modal = false" color="danger">CANCEL</CButton>
				<CButton @click="save" :disabled="loading" color="success"
					>SAVE</CButton
				>
			</template>
		</CModal>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import PromoDetail from "./PromoDetail";

export default {
	components: {
		PromoDetail,
	},
	data() {
		return {
			uri: basuri + "promo",
			moreParams: { filter: null },
			selectedId: null,
			loading: false,
			modal: false,
			detailRow: PromoDetail,
			frm: {
				name: "",
				discount: 0,
				bte: "",
				condition: "",
				start: new Date(Date.now()).toISOString().split("T")[0],
				end: new Date(Date.now()).toISOString().split("T")[0],
				active: true,
			},
			fields: [
				{
					name: "name",
					title: "Name",
					sortField: "name",
				},
				{
					name: "discount",
					title: "Disc.",
					sortField: "discount",
				},
				{
					name: "bte",
					title: "BTE",
					sortField: "bte",
				},
				{
					name: "start",
					title: "Start",
					sortField: "start",
				},
				{
					name: "end",
					title: "End",
					sortField: "end",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function(val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null };
			this.$refs.vuetable.refresh();
		},
		add(data = null) {
			if (data) {
				this.frm = JSON.parse(JSON.stringify(data));
				this.selectedId = data.id;
			} else {
				this.frm = {
					name: "",
					discount: 0,
					bte: "",
					condition: "",
					start: new Date(Date.now()).toISOString().split("T")[0],
					end: new Date(Date.now() + 86400000 * 30).toISOString().split("T")[0],
					active: true,
				};
				this.selectedId = null;
			}
			this.modal = true;
		},
		save: debounce(async function() {
			this.loading = true;

			try {
				let datar = {};
				if (this.selectedId) {
					const { data } = await this.$http.put(
						"promo/" + this.selectedId,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("promo", this.frm);
					datar = data;
				}
				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
					this.$refs.vuetable.refresh();
					this.modal = false;
				} else {
					this.$swal("Fail !", datar.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loading = false;
		}, 1000),
	},
};
</script>
